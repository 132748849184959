import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { NONE } from "../../constants";

export async function openOrgEventRegistrations__server__updateSessionSelection(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  sesstionSelection: Partial<OpenOrgEventRegistration["tryoutInfo"]["sessionSelection"]>;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const openOrgEventRegistration = await h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID);

  if (!openOrgEventRegistration) {
    throw new Error(`Can't find the registration ${p.openOrgEventRegistrationID}`);
  }

  let newSessionSelection = { ...p.sesstionSelection };

  if (p.sesstionSelection.orgTeamTagId === NONE || p.sesstionSelection.orgTeamTagId === h._MagicDeleteValue) {
    newSessionSelection = { orgTeamTagId: h._MagicDeleteValue };
  }

  await h.OpenOrgEventRegistration.update({
    id: p.openOrgEventRegistrationID,
    doc: {
      tryoutInfo: {
        sessionSelection: newSessionSelection
      }
    }
  });
}

openOrgEventRegistrations__server__updateSessionSelection.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
