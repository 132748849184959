import { OpenOrgEventRegistrationId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { translate } from "@ollie-sports/i18n";
import { getRegistrationStatus, sendOrgEmail } from "../../utils";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function openOrgEventRegistrations__server__cancelTeamInvite(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  teamId: string;
  selfAccountId: string;
  orgId: string;
}) {
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();

  const [existingRegistration, team, sender, org] = await Promise.all([
    h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID),
    h.Team.getDoc(p.teamId),
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId)
  ]);

  if (!team || (!team.accounts[p.selfAccountId] && !org?.accounts[p.selfAccountId]) || !sender) {
    throw new Error("Unable to cancel invite for this team id! " + p.teamId);
  }

  if (!existingRegistration) {
    throw new Error("Unable to find openOrgEventRegistrationId! " + p.openOrgEventRegistrationID);
  }

  try {
    const registrationStatus = getRegistrationStatus(existingRegistration.tryoutInfo.actions || [], p.teamId);

    if (registrationStatus !== "invite-pending") {
      throw new Error("Unable to cancel an invite to a registrant without a pending invite!");
    }

    await h.OpenOrgEventRegistration.updateShallow({
      id: p.openOrgEventRegistrationID,
      doc: {
        tryoutInfo: {
          ...existingRegistration.tryoutInfo,
          actions: [
            ...(existingRegistration.tryoutInfo.actions || []),
            {
              type: "cancel-invite",
              createdAtMS: Date.now(),
              teamId: p.teamId,
              triggeredByAccountId: p.selfAccountId
            }
          ]
        }
      }
    });

    const serverLocale = existingRegistration.locale ?? "en-us";

    await sendOrgEmail({
      orgId: existingRegistration.orgId,
      personalizations: [
        {
          email: existingRegistration.guardianContactInfo.email,
          message: translate(
            { defaultMessage: "{coachName} has canceled the offer for {playerName} to join {teamName}.", serverLocale },
            {
              teamName: team.name,
              playerName: existingRegistration.playerInfo.firstName + " " + existingRegistration.playerInfo.lastName,
              coachName: sender.firstName + " " + sender.lastName
            }
          ),
          name: existingRegistration.guardianContactInfo.firstName,
          subject: translate({ defaultMessage: "Offer Canceled - {teamName}", serverLocale }, { teamName: team.name })
        }
      ]
    });
  } catch (e) {
    const payload = {
      error: (e as any)?.message,
      triggeringAccountId: p.selfAccountId,
      teamId: p.teamId,
      openOrgEventRegistrationId: p.openOrgEventRegistrationID
    };
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-problem-canceling-tryout-invite",
      payload,
      slackImportantErrorsMessage: `Unable to properly cancel tryout invitation. ${JSON.stringify(payload, null, 2)}`
    });
  }
}

openOrgEventRegistrations__server__cancelTeamInvite.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
