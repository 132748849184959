import { getUniversalHelpers } from "../../helpers";
import { AccountId, OpenOrgEventRegistrationId, OrgId, TeamId } from "@ollie-sports/models";
import shortid from "shortid";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

const IMAGE_DIRECTORY = "open_org_event/";

export async function openOrgEventRegistrations__client__uploadPhoto(p: {
  blob: any;
  openOrgEventRegistrationId: OpenOrgEventRegistrationId;
}): Promise<string | undefined> {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const storageUri = await uploadTask.snapshot.ref.getDownloadURL();

  return storageUri as string;
}

// i18n certified - complete
