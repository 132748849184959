import { OpenOrgEvent, OpenOrgEventRegistration, OpenOrgEventRegistrationId, OrgId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export function openOrgEventRegistrations__client__getRegistrationSubscription(p: {
  openOrgEventRegistrationId: OpenOrgEventRegistrationId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.OpenOrgEventRegistration.docSubscription(p.openOrgEventRegistrationId));
}
