import {
  AccountId,
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function openOrgEventRegistrations__server__deleteRegistration(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  orgId: OrgId;
  accountId: AccountId;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const org = await h.Org.getDoc(p.orgId);

  if (!org) {
    throw new Error("Could not find the org.");
  }

  if (!org.accounts[p.accountId]?.exists) {
    throw new Error("Could not delete the registration because the account is not an org admin.");
  }

  await h.OpenOrgEventRegistration.delete({ id: p.openOrgEventRegistrationID });
}

openOrgEventRegistrations__server__deleteRegistration.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
