import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";

export async function openOrgEventRegistrations__server__updateNotes(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  notes: OpenOrgEventRegistrationNote[];
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.OpenOrgEventRegistration.update({
    id: p.openOrgEventRegistrationID,
    doc: {
      tryoutInfo: {
        notes: p.notes.length ? p.notes : h._MagicDeleteValue
      }
    }
  });
}

openOrgEventRegistrations__server__updateNotes.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
