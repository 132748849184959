import { AccountId, OpenOrgEventRegistrationId, OpenOrgEventRegistrationNote, TeamId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function openOrgEventRegistrations__server__toggleLike(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  teamId: TeamId;
  triggeredByAccountId: AccountId;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const existing = await h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID);

  if (!existing) {
    throw new Error("Cannot find open org event! " + p.openOrgEventRegistrationID);
  }

  const existingActionIndex = existing.tryoutInfo.actions?.findIndex(a => a.teamId === p.teamId && a.type === "favorite");

  const newActions = (existing.tryoutInfo.actions || []).slice();
  if (existingActionIndex === undefined || existingActionIndex === -1) {
    newActions.push({
      createdAtMS: Date.now(),
      teamId: p.teamId,
      triggeredByAccountId: p.triggeredByAccountId,
      type: "favorite"
    });
  } else {
    newActions.splice(existingActionIndex, 1);
  }

  await h.OpenOrgEventRegistration.updateShallow({
    id: p.openOrgEventRegistrationID,
    doc: {
      tryoutInfo: {
        ...existing.tryoutInfo,
        actions: newActions
      }
    }
  });
}

openOrgEventRegistrations__server__toggleLike.auth = () => {};
