import { OpenOrgEvent, OpenOrgEventRegistration, OpenOrgEventRegistrationId, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function openOrgEventRegistrations__server__updateRegistrationTemporartyId(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  newTemporaryId: string;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.OpenOrgEventRegistration.update({
    id: p.openOrgEventRegistrationID,
    doc: {
      playerInfo: {
        temporaryIdentifier: p.newTemporaryId
      }
    }
  });
}

openOrgEventRegistrations__server__updateRegistrationTemporartyId.auth = () => {};
