import { GENDERS, OpenOrgEvent, OpenOrgEventId, OpenOrgEventRegistration, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import _ from "lodash";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { OpenOrgEventGetRegistrationProps, getOpenOrgEventRegistrations } from "../../utils/open-org-event-utils";

export async function openOrgEventRegistrations__server__getRegistrations(p: OpenOrgEventGetRegistrationProps) {
  return await getOpenOrgEventRegistrations(p);
}

openOrgEventRegistrations__server__getRegistrations.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
