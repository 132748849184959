import { CODE_TYPES, OpenOrgEventRegistrationId } from "@ollie-sports/models";

import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { translate } from "@ollie-sports/i18n";
import { generateUniversalLinkForCode, getRegistrationStatus, sendOrgEmail } from "../../utils";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";
import { teams__server__getInviteCode } from "../teams.api";

export async function openOrgEventRegistrations__server__markInviteAsAccepted(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  teamId: string;
  selfAccountId: string;
  orgId: string;
}) {
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();

  const [existingRegistration, team, sender, org] = await Promise.all([
    h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID),
    h.Team.getDoc(p.teamId),
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId)
  ]);

  if (!team || (!team.accounts[p.selfAccountId] && !org?.accounts[p.selfAccountId]) || !sender) {
    throw new Error("Unable to mark an invite as accepted for this team id! " + p.teamId);
  }

  if (!existingRegistration) {
    throw new Error("Unable to find openOrgEventRegistrationId! " + p.openOrgEventRegistrationID);
  }

  try {
    const registrationStatus = getRegistrationStatus(existingRegistration.tryoutInfo.actions || [], p.teamId);

    if (registrationStatus !== "invite-pending") {
      throw new Error("Unable to mark an invite as accepted for a registrant without a pending invite!");
    }

    const [, code] = await Promise.all([
      h.OpenOrgEventRegistration.updateShallow({
        id: p.openOrgEventRegistrationID,
        doc: {
          tryoutInfo: {
            ...existingRegistration.tryoutInfo,
            actions: [
              ...(existingRegistration.tryoutInfo.actions || []),
              {
                type: "manual-player-accept",
                createdAtMS: Date.now(),
                teamId: p.teamId,
                triggeredByAccountId: p.selfAccountId
              }
            ]
          }
        }
      }),
      teams__server__getInviteCode({
        teamId: p.teamId,
        type: CODE_TYPES.joinTeam
      })
    ]);

    const serverLocale = existingRegistration.locale ?? "en-us";

    const codeString = code
      ? String(code.code)
          .replace(/(\d\d\d)/g, "-$1")
          .replace("-", "")
      : "";

    const universalUrl = code
      ? generateUniversalLinkForCode({ code: code.code })
      : generateUniversalLinkForCode({ code: "download" });

    await sendOrgEmail({
      orgId: existingRegistration.orgId,
      personalizations: [
        {
          email: existingRegistration.guardianContactInfo.email,
          message: `${translate(
            { defaultMessage: "{coachName} has marked {playerName} as accepted to join {teamName}.", serverLocale },
            {
              teamName: team.name,
              playerName: existingRegistration.playerInfo.firstName + " " + existingRegistration.playerInfo.lastName,
              coachName: sender.firstName + " " + sender.lastName
            }
          )}<br><br><strong>${translate({
            defaultMessage: "Now it's time to join the team on the Ollie app to access the team schedule and communication.",
            serverLocale
          })}</strong><br>
        ${translate({
          defaultMessage: "Use the following link (on your phone) and code to download the Ollie app and join the team!",
          serverLocale
        })}<br><br>
        <strong>${translate.common(serverLocale).Link}:</strong> <a href="${universalUrl}">${universalUrl}</a><br>
        <strong>${translate.common(serverLocale).Code}:</strong> ${codeString}<br><br>
        <strong>${translate({ defaultMessage: "Already have the app?", serverLocale })}</strong><br>
        ${translate({
          defaultMessage: "Just click the link on your phone or find the Join Team button on the Teams tab and enter the code.",
          serverLocale
        })}`,
          name: existingRegistration.guardianContactInfo.firstName,
          subject: translate({ defaultMessage: "Offer Accepted - {teamName}", serverLocale }, { teamName: team.name })
        }
      ]
    });
  } catch (e) {
    const payload = {
      error: (e as any)?.message,
      triggeringAccountId: p.selfAccountId,
      teamId: p.teamId,
      openOrgEventRegistrationId: p.openOrgEventRegistrationID
    };
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-problem-canceling-tryout-invite",
      payload,
      slackImportantErrorsMessage: `Unable to properly cancel tryout invitation. ${JSON.stringify(payload, null, 2)}`
    });
  }
}

openOrgEventRegistrations__server__markInviteAsAccepted.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
