import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";
import express from "express";
import {
  Account,
  AccountPrivate,
  CODE_TYPES,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  NotificationBundle,
  NotificationType,
  OpenOrgEventRegistrationId,
  OrgId,
  PushNotificationSettingToRespect,
  TeamId,
  TryoutRegistrationAction
} from "@ollie-sports/models";
import { getRegistrationStatus } from "../../utils/open-org-event-utils";
import { generateUniversalLinkForCode, isProduction, sendOrgEmail, shouldSendEmail } from "../../utils";
import { translate } from "@ollie-sports/i18n";
import { teams__server__getInviteCode } from "../teams.api";
import moment from "moment";
import { processNotificationBundles } from "../notification/notification.plumbing";
import { generatePushID } from "../../internal-utils/firebaseId";

export async function openOrgEventRegistrations__server__respondToOffer(p: {
  openOrgEventRegistrationId: OpenOrgEventRegistrationId;
  teamId: TeamId;
  orgId: OrgId;
  response: "accept" | "reject";
}) {
  const { appOllieFirestoreV2: h, injectedServerLibraries } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  const [registration, team, code] = await Promise.all([
    h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationId),
    h.Team.getDoc(p.teamId),
    teams__server__getInviteCode({
      teamId: p.teamId,
      type: CODE_TYPES.joinTeam
    })
  ]);
  if (!registration) {
    await olliePipe.emitEvent({
      type: "error-tryout-response-no-registration-found",
      payload: p
    });
    throw new Error("Cannot respond to an offer for a registration that does not exist.");
  }
  if (!team) {
    await olliePipe.emitEvent({
      type: "error-tryout-response-no-team-found",
      payload: p
    });
    throw new Error("Cannot respond to an offer for a team that does not exist.");
  }

  const status = getRegistrationStatus(registration.tryoutInfo.actions ?? [], p.teamId);

  if (status !== "invite-pending") {
    await olliePipe.emitEvent({
      type: "error-tryout-response-no-invite-pending",
      payload: p
    });
    throw new Error("Cannot respond to an offer that does not have an invite pending.");
  }
  const correspondingInvite = _.orderBy(registration.tryoutInfo.actions ?? [], a => a.createdAtMS, "desc").find(
    a => a.type === "invite" && a.teamId === p.teamId
  );
  if (!correspondingInvite) {
    await olliePipe.emitEvent({
      type: "error-tryout-response-no-corresponding-invite",
      payload: p
    });
    throw new Error("Cannot respond to an offer that does not have a corresponding invite.");
  }

  let inviteAccount: Account | null = null;
  let inviteAccountPrivate: AccountPrivate | null = null;

  if (correspondingInvite.triggeredByAccountId) {
    [inviteAccount, inviteAccountPrivate] = await Promise.all([
      h.Account.getDoc(correspondingInvite.triggeredByAccountId),
      h.AccountPrivate.getDoc(correspondingInvite.triggeredByAccountId)
    ]);
  }

  const newAction: TryoutRegistrationAction = {
    type: p.response === "accept" ? "player-accept" : "player-reject",
    createdAtMS: Date.now(),
    teamId: p.teamId
  };
  try {
    await h.OpenOrgEventRegistration.update({
      id: p.openOrgEventRegistrationId,
      doc: { tryoutInfo: { actions: [...(registration.tryoutInfo.actions ?? []), newAction] } }
    });
  } catch (e) {
    await olliePipe.emitEvent({
      type: "error-tryout-response-when-adding-action",
      payload: p
    });
    throw new Error("There was an error saving the response");
  }
  const serverLocale = registration.locale ?? "en-us";

  const codeString = code
    ? String(code.code)
        .replace(/(\d\d\d)/g, "-$1")
        .replace("-", "")
    : "";

  const universalUrl = code
    ? generateUniversalLinkForCode({ code: code.code })
    : generateUniversalLinkForCode({ code: "download" });

  // Send the coach a notification either way
  if (inviteAccountPrivate) {
    try {
      const lp: LowPriorityNotificationDetail = {
        body:
          p.response === "accept"
            ? translate(
                {
                  defaultMessage: `{playerName} has accepted your offer.`,
                  serverLocale: inviteAccountPrivate.communicationLocale
                },
                { playerName: `${registration.playerInfo.firstName} ${registration.playerInfo.lastName}` }
              )
            : translate(
                {
                  defaultMessage: `{playerName} has declined your offer.`,
                  serverLocale: inviteAccountPrivate.communicationLocale
                },
                { playerName: `${registration.playerInfo.firstName} ${registration.playerInfo.lastName}` }
              ),
        title:
          p.response === "accept"
            ? translate({
                defaultMessage: `Offer Accepted`,
                description: "Alerting a coach that an offer has been accepted",
                serverLocale: inviteAccountPrivate.communicationLocale
              })
            : translate({
                defaultMessage: `Offer Declined`,
                description: "Alerting a coach that an offer has been declined",
                serverLocale: inviteAccountPrivate.communicationLocale
              }),
        createdAtMS: Date.now(),
        expireAtMS: moment().add(30, "days").valueOf(),
        id: h.LowPriorityNotificationDetail.generateId(),
        routerPath: `main/tools/openOrgEventRegistrations/${p.orgId}/tryout/NONE`,
        type: LowPriorityNotificationDetailType.tryoutOfferResponse
      };

      await h.LowPriorityNotificationDetail.add({ doc: lp });

      const notificationId = generatePushID();
      const now = Date.now();
      const triggerEventId = `tryout-response-${p.openOrgEventRegistrationId}-${p.teamId}-${p.response}-${Date.now()}`;

      const notificationBundle: NotificationBundle = {
        accountId: inviteAccountPrivate.id,
        type: NotificationType.lowPriorityNotification,
        id: notificationId,
        triggerEventId,
        pushNotificationData: {
          body: lp.body,
          title: lp.title,
          id: notificationId,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
          triggerEventId,
          type: NotificationType.lowPriorityNotification,
          routerPath: lp.routerPath,
          lowPriorityNotificationDetailType: lp.type
        },
        realTimeNotification: {
          d: now,
          id: notificationId,
          t: NotificationType.lowPriorityNotification,
          e: moment().add(30, "days").valueOf(),
          lpId: lp.id
        }
      };

      await processNotificationBundles({
        notificationBundles: [notificationBundle]
      });
    } catch (e) {
      await olliePipe.emitEvent({
        type: "error-tryout-sending-coach-notification-of-response",
        payload: p
      });
    }
  }

  try {
    // Send the responder an email if they accept with instructions on how to join Ollie
    if (p.response === "accept") {
      const email = registration.guardianContactInfo.email;
      if (isProduction() || shouldSendEmail(email)) {
        await sendOrgEmail({
          orgId: p.orgId,
          personalizations: [
            {
              email: registration.guardianContactInfo.email,
              name: registration.guardianContactInfo.firstName,
              message: `${translate(
                {
                  defaultMessage: "Congratulations on accepting the offer for {playerName} to join {teamName}.",
                  serverLocale
                },
                { playerName: registration.playerInfo.firstName, teamName: team.name }
              )}<br><br><strong>${translate({
                defaultMessage: "Now it's time to join the team on the Ollie app to access the team schedule and communication.",
                serverLocale
              })}</strong><br>
            ${translate({
              defaultMessage: "Use the following link (on your phone) and code to download the Ollie app and join the team!",
              serverLocale
            })}<br><br>
            <strong>${translate.common(serverLocale).Link}:</strong> <a href="${universalUrl}">${universalUrl}</a><br>
            <strong>${translate.common(serverLocale).Code}:</strong> ${codeString}<br><br>
            <strong>${translate({ defaultMessage: "Already have the app?", serverLocale })}</strong><br>
            ${translate({
              defaultMessage:
                "Just click the link on your phone or find the Join Team button on the Teams tab and enter the code.",
              serverLocale
            })}`,
              subject: translate({ defaultMessage: "Offer Accepted - Next Steps", serverLocale })
            }
          ],
          replyToEmailAddress: inviteAccount?.email
        });
      }
    }
  } catch (e) {
    await olliePipe.emitEvent({
      type: "error-tryout-response-sending-email-confirmation-of-acceptance",
      payload: { ...p, error: ("message" in (e as any) ? (e as any).message : "") as any }
    });
    // Don't blow things up because the notifications don't go out
  }
}

openOrgEventRegistrations__server__respondToOffer.auth = async (req: any) => {};
