import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { isProduction, sanitizeEmailMessageByRemovingHTMLElements, shouldSendEmail } from "../../utils";
import { OpenOrgEventGetRegistrationProps, getOpenOrgEventRegistrations } from "../../utils/open-org-event-utils";
import _ from "lodash";
import express from "express";
import { OrgEmailRequiredPersonalizations, sendOrgEmail } from "../../utils";
import { linkify } from "../../utils/linkify";

export async function openOrgEventRegistrations__server__sendEmail(
  p: OpenOrgEventGetRegistrationProps & {
    message: string;
    replyToEmailAddress?: string;
    orgName: string;
    subject: string;
  }
) {
  const { injectedServerLibraries, appOllieFirestoreV2: h } = getServerHelpers();

  let personalizations: OrgEmailRequiredPersonalizations[] = [];

  const [org, orgSettings] = await Promise.all([h.Org.getDoc(p.orgId), h.OrgSettings.getDoc(p.orgId)]);

  if (!org) {
    throw new Error("Cannot find the Org: " + p.orgId);
  }
  if (!orgSettings) {
    throw new Error("Cannot find the Org Settings: " + p.orgId);
  }

  if (!org.accounts[p.selfAccountId]?.exists) {
    throw new Error("Only Org admins can send emails");
  }

  let offset = 0;
  let limit = 500;
  async function fetchRegistrations() {
    return await getOpenOrgEventRegistrations({ ...p, offset, limit });
  }

  while (true) {
    const data = await fetchRegistrations();
    // We didn't get new registrations, so break out
    if (!data.registrations.length) {
      break;
    }

    personalizations = [
      ...personalizations,
      ...data.registrations.map(registration => {
        return {
          email: registration.guardianContactInfo.email,
          name: `${registration.guardianContactInfo.firstName} ${registration.guardianContactInfo.lastName}`,
          message: linkify(sanitizeEmailMessageByRemovingHTMLElements(p.message)),
          subject: p.subject
        } as OrgEmailRequiredPersonalizations;
      })
    ];

    offset = offset + limit;
  }
  await sendOrgEmail({
    org,
    orgSettings,
    personalizations: _.uniqBy(personalizations, a => a.email),
    replyToEmailAddress: orgSettings.tryoutSettings?.defaultReplyToEmailAddress,
    orgId: org.id
  });
}

openOrgEventRegistrations__server__sendEmail.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
