import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import moment from "moment-timezone";
import { getServerHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { sendTwilioSMS } from "../../utils/twilio-sdk";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import { getRegistrationStatus, sanitizeEmailMessageByRemovingHTMLElements, sendOrgEmail, setKeyValueStore } from "../../utils";
import { linkify } from "../../utils/linkify";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function openOrgEventRegistrations__server__sendTeamAlternateInvitation(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  teamId: string;
  orgId: string;
  subject: string;
  selfAccountId: string;
  emailText: string;
  replyToEmail: string;
  replyToName: string;
}) {
  const completedActions = { saveToDB: false, sentEmail: false, sentText: false };
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();

  const [existingRegistration, team, sender, org] = await Promise.all([
    h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID),
    h.Team.getDoc(p.teamId),
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId)
  ]);

  if (!team || (!team.accounts[p.selfAccountId] && !org?.accounts[p.selfAccountId]) || !sender) {
    throw new Error("Unable to send alternate notifications for this team id! " + p.teamId);
  }

  if (!existingRegistration) {
    throw new Error("Unable to find openOrgEventRegistrationId! " + p.openOrgEventRegistrationID);
  }

  try {
    const openOrgEvent = await h.OpenOrgEvent.getDoc(existingRegistration.openOrgEventId);

    if (!openOrgEvent) {
      throw new Error("Unable to find openOrgEvent! " + existingRegistration.openOrgEventId);
    }

    const registrationStatus = getRegistrationStatus(existingRegistration.tryoutInfo.actions || [], p.teamId);

    if (registrationStatus === "invite-pending") {
      throw new Error("Unable to send an alternate notification to a registrant with a pending invite!");
    }

    if (registrationStatus === "invite-accepted" || registrationStatus === "manual-player-accept") {
      throw new Error("Unable to send an alternate notification to a registrant who has already accepted your team's invite!");
    }

    await h.OpenOrgEventRegistration.updateShallow({
      id: p.openOrgEventRegistrationID,
      doc: {
        tryoutInfo: {
          ...existingRegistration.tryoutInfo,
          actions: [
            ...(existingRegistration.tryoutInfo.actions || []),
            {
              type: "alternateNotificationSent",
              createdAtMS: Date.now(),
              teamId: p.teamId,
              triggeredByAccountId: p.selfAccountId
            }
          ]
        }
      }
    });

    completedActions.saveToDB = true;

    const cleanedEmailText = linkify(sanitizeEmailMessageByRemovingHTMLElements(p.emailText));
    const serverLocale = existingRegistration.locale ?? "en-us";

    await Promise.all([
      sendTwilioSMS({
        body: translate(
          {
            defaultMessage:
              "{playerFirstName} is an alternate for {teamShortName}! Please check your email, including your Junk folder.",
            serverLocale
          },
          {
            playerFirstName: existingRegistration.playerInfo.firstName.trim(),
            teamShortName: team.name.trim()
          }
        ),
        to: existingRegistration.guardianContactInfo.phoneNumber
      })
        .then(() => {
          completedActions.sentText = true;
        })
        .catch(async e => {
          if (e?.status?.toString().match(/4\d\d/)) {
            //Do nothing... Invalid phone number
          } else {
            await new Promise(res => setTimeout(res, 500)); //Give the other request chance to return
            throw e;
          }
        }),
      sendOrgEmail({
        orgId: existingRegistration.orgId,
        replyToEmailAddress: p.replyToEmail,
        replyToName: p.replyToName,
        personalizations: [
          {
            email: existingRegistration.guardianContactInfo.email,
            message: cleanedEmailText,
            name: existingRegistration.guardianContactInfo.firstName,
            subject: p.subject
          }
        ]
      })
        .then(() => {
          completedActions.sentEmail = true;
        })
        .catch(async e => {
          if (e?.code?.toString().match(/4\d\d/)) {
            //Do nothing... Invalid phone email
          } else {
            await new Promise(res => setTimeout(res, 500)); //Give the other request chance to return
            throw e;
          }
        })
    ]);

    return {
      success: true,
      ...completedActions
    };
  } catch (e) {
    const payload = {
      error: (e as any)?.message,
      triggeringAccountId: p.selfAccountId,
      teamId: p.teamId,
      openOrgEventRegistrationId: p.openOrgEventRegistrationID
    };
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-problem-sending-tryout-alternate-notification",
      payload,
      slackImportantErrorsMessage: `Unable to properly send tryout alternate notifaction. ${JSON.stringify(payload, null, 2)}`
    });

    return {
      success: completedActions.saveToDB,
      ...completedActions
    };
  }
}

openOrgEventRegistrations__server__sendTeamAlternateInvitation.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
