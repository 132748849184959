import { GENDERS, OpenOrgEvent, OpenOrgEventId, OpenOrgEventRegistration, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

// TODO: Can remove this after August 2023 update is a required update
export async function openOrgEventRegistrations__server__getOrgsWithRecentTryoutRegistrations(p: {
  orgIds: string[];
  type: "tryout" | "camp";
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const registrations = await h.OpenOrgEventRegistration.multiQuery({
    queries: p.orgIds.map(id => ({
      where: [
        { orgId: ["==", id] },
        { type: ["==", p.type as any] },
        { createdAtMS: [">=", Date.now() - 1000 * 60 * 60 * 24 * 90] }
      ],
      limit: 1
    }))
  });

  return registrations?.docs.map(r => r.orgId) || [];
}

openOrgEventRegistrations__server__getOrgsWithRecentTryoutRegistrations.auth = () => {};
