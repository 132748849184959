import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function openOrgEventRegistrations__server__updateGuardianInfo(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  guardianContactInfo: Partial<OpenOrgEventRegistration["guardianContactInfo"]>;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.OpenOrgEventRegistration.update({
    id: p.openOrgEventRegistrationID,
    doc: {
      guardianContactInfo: p.guardianContactInfo
    }
  });
}

openOrgEventRegistrations__server__updateGuardianInfo.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
