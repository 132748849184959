import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function openOrgEventRegistrations__server__updatePlayerInfo(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  playerInfo: Partial<OpenOrgEventRegistration["playerInfo"]>;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.OpenOrgEventRegistration.update({
    id: p.openOrgEventRegistrationID,
    doc: {
      playerInfo: p.playerInfo
    }
  });
}

openOrgEventRegistrations__server__updatePlayerInfo.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
